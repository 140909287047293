<template>
  <div></div>
</template>

<script>
import { cognito } from "@/config/reducerproperties";
import Console from "@/console";

export default {
  name: "XeroSignUp",
  async mounted() {
    this.signIn();
  },
  methods: {
    signIn() {
      var signInUrl =
        cognito.endPoint +
        "/oauth2/authorize?identity_provider=Xero&redirect_uri=" +
        cognito.callBackUrl +
        "&response_type=code&client_id=" +
        cognito.clientId +
        "&scope=openid+aws.cognito.signin.user.admin";
      Console.log(signInUrl);
      window.location.href = signInUrl;
      localStorage.redirect = "/sign-up-xero/complete";
    }
  }
};
</script>
