<template>
  <div>Redirecting to Xero...</div>
</template>

<script>
import ApiHelper from "@/helper/apihelper";
import { EventBus } from "@/components/eventbus";
import Console from "@/console";

export default {
  name: "XeroSignUpComplete",
  async mounted() {
    this.authenticateForExtract();
  },
  methods: {
    async authenticateForExtract() {
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}connections/start?platform=xero&callback=extract`)
        .then(response => {
          if (response.data.secretState) {
            localStorage.setItem("state", response.data.secretState);
          }
          if (response.data.redirect) {
            window.location = response.data.redirect;
          } else {
            EventBus.$emit("show-toast", {
              message: "Sorry, there was an error starting the analysis.",
              variant: "warning"
            });
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch(e => {
          Console.log(e);
          EventBus.$emit("show-toast", {
            message: "Sorry, there was an error starting the analysis.",
            variant: "warning"
          });
          this.$router.push({ name: "dashboard" });
        });
    }
  }
};
</script>
